import axios from 'axios'

const client = axios.create({
  baseURL: process.env.common.nowApi.host,
})

client.interceptors.request.use(
  (config) => {
    if (config.isInterceptor !== false) {
      if (
        config.headers.Authorization == null ||
        config.headers.Authorization.startsWith('Basic') === false
      ) {
        let token = localStorage.getItem(process.env.common.token.name)
        if (token != null) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

client.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      location.reload()
    }
    return Promise.reject(error.response)
  }
)

export default {
  getStores() {
    return client.get('/api/aicrm/stores')
  },
  getReviews(storeId) {
    return client.get(`/api/aicrm/reviews/?store_id=${storeId}`)
  },
  createStore(data) {
    return client.post('/api/aicrm/stores/', data)
  },
  scrapeReviews(storeId) {
    return client.post(`/api/aicrm/stores/${storeId}/scrape_reviews/`)
  }
}
