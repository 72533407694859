<template>
  <v-container fluid class="pt-0">
    <PageHeader title="스마트스토어 목록" />
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              :server-items-length="totalItems"
              class="elevation-1"
              @click:row="onRowClick"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="검색"
                    single-line
                    hide-details
                    @input="onSearch"
                    @keyup.enter="onSearch"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="$router.push({ name: 'ai/smart-store/search' })"
                  >
                    스토어 추가
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ formatDate(item.created_at) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import smartStoreApi from '@/plugins/api/now/smart-store'
import PageHeader from '@/components/common/page/PageHeader.vue'

export default {
  name: 'SmartStoreList',
  components: {
    PageHeader,
  },
  data() {
    return {
      search: '',
      loading: false,
      options: {},
      totalItems: 0,
      headers: [
        { text: 'ID', value: 'id' },
        { text: '스토어명', value: 'store.name' },
        { text: '네이버 플레이스 ID', value: 'naverplace_id' },
        { text: '주소', value: 'store.address1' },
        { text: '생성일', value: 'created_at' },
      ],
      items: [],
    }
  },
  methods: {
    async fetchItems() {
      this.loading = true
      try {
        const response = await smartStoreApi.getStores()
        let filteredItems = response.data || []

        // 검색어가 있는 경우 필터링
        if (this.search) {
          const searchLower = this.search.toLowerCase()
          filteredItems = filteredItems.filter(item =>
            item.store.name.toLowerCase().includes(searchLower) ||
            item.store.address1.toLowerCase().includes(searchLower)
          )
        }

        this.items = filteredItems
        this.totalItems = filteredItems.length
      } catch (error) {
        console.error('스토어 목록 조회 실패:', error)
        this.items = []
        this.totalItems = 0
      } finally {
        this.loading = false
      }
    },
    onSearch() {
      this.options.page = 1
      this.fetchItems()
    },
    onRowClick(item) {
      this.$router.push({
        name: 'ai/smart-store/reviews',
        params: { id: item.id }
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  created() {
    this.fetchItems()
  },
}
</script>

<style scoped>
.v-card {
  margin: 16px;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>
